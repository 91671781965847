body {
    background-color: #ffffff;
}

.product {
    margin-left: 27px;
    margin-right: 27px;
}

.img-pr {
    width: 100%;
}

.img-section {
    margin-top: 45px;
}

.view-txt {
    font-size: 20px;
    font-weight: 500;
}

.view {
    margin-top: 46px;
    margin-left: 12px;
}

.view-pos {
    border: 1px solid;
    padding: 9px;
    border-radius: 20px;
    font-size: 12px;
}