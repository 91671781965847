html, body {
    margin: 0;
    padding: 0;
    height: 100%;
}

#root {
    background-color: #E19400;
}

.lg-body {
    display: flex;
    justify-content: center; /* Centers horizontally */
    align-items: flex-start; /* Aligns items to the start of the container */
    height: 100vh; /* Full viewport height */
}

.lg-main {
    padding: 20px;
    border-radius: 8px;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
    margin-top: 20px; /* Adjust this value to move it down or up from the top */
    align-self: flex-start; /* Aligns itself to the start of the flex container */
}

/* .hd {
    display: flex;
} */

.hd-h, .hd-p {
    text-align: center;
    color: white;
}

.hd {
    margin-top: 10%;
    margin-bottom: 3em;
}

.card-form {
    background-color: white;
    /* height: 100%; */
    padding-bottom: 70%;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Adds an inner shadow for the input field */
}

.card-form1 {
    padding-bottom: 40%;
}

.in {
    width: 90%;
    padding-top: 1.2em;
    padding-bottom: 1.2em;
    margin: 12px;
    background-color: #DCDCDC;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-sizing: border-box; /* Ensures padding and border are included in the element's total width and height */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Adds an inner shadow for the input field */
    margin-left: 20px;
    outline: none;
    /* font-size: 20px; */
}

.in1 {
    margin-top: 30px;
}

.bt {
    background-color: #E19400;
    color: white;
    font-size: 16px;
}

.in::placeholder {
    font-size: 15px;
    padding-left: 15px;
}

.fr-p {
    text-align: center;
}

.agree-terms {
    display: flex;
    justify-content: center;
}

.agree-text {
    font-size: 12px;
}